<template>
  <div class="text-center">
    <v-dialog v-model="open" width="650" persistent>
      <v-card class="global-modalCard5">
        <v-layout wrap class="pa-0">
          <v-flex xs10 class="text-left ml-8 pt-10" style="z-index: 10">
            <h1 class="global-text5" style="color: orange">Aviso</h1>
            <v-flex xs8 class="text-left" v-if="errorArray">
              <p
                class="mt-3 global-subtitle-modal"
                v-for="value in error"
                :key="value.id"
              >
                {{ value }}
              </p>
            </v-flex>
            <v-flex xs8 class="text-left" v-else>
              <p class="mt-3 global-subtitle-modal">
                {{ error }}
              </p>
            </v-flex>
            <v-layout class="mt-8">
              <div
                id="ComputerSvg"
                class="ml-0"
                style="align-items: center; justify-content: center"
              >
                <v-img
                  class="image-error"
                  width="150px"
                  height="150px"
                  src="@/assets/modalImages/warning.png"
                />
              </div>
            </v-layout>
          </v-flex>
          <v-img
            width="400px"
            id="BackgroundSvgs"
            src="@/assets/modalImages/Vector.png"
          />
          <v-flex class="d-flex align-center justify-center ml-5 mb-6">
            <v-btn
              color="primary"
              class="mt-9 botonError text-none"
              width="214"
              height="41"
              :loading="loading"
              @click="Reload"
              >Reintentar
              <v-icon right>mdi-refresh</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["open", "error", "id", "errorArray"],
  data: () => ({
    dialog: true,
    loading: false,
    dataNewQuot: null,
    validateQuot: true,
  }),
  methods: {
    Reload() {
      this.loading = true;
      location.reload();
    },
  },
  computed: {
    btnColor() {
      return this.getBotonColor;
    },
  },
};
</script>

<style scoped>
h4 {
  z-index: 10;
}
.image-error {
  position: absolute;
  top: 17%;
  right: 10%;
  z-index: 0;
}
.botonError {
  position: absolute;
  top: 55%;
  right: 40%;
  z-index: 0;
}
#BackgroundSvgs {
  position: absolute;
  top: 0%;
  right: 0%;
  max-width: 500px;
}
#ComputerSvg {
  z-index: 10;
}
:deep() .v-dialog {
  border-radius: 4px;
  margin: 24px;
  height: 270px;
  overflow: hidden;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
}
</style>
